import Link from "Utils/LinkWrapper";

const VATComponent = ({ data, original = true }) => {
    const getMostOccurringRateAndInfo = (vatData) => {
        const rateCounts = {};
        let highestAccuracyInfo = null;

        vatData?.forEach((item) => {
            item.rate.split(',').forEach((rate) => {
                const trimmedRate = rate.trim();
                rateCounts[trimmedRate] = (rateCounts[trimmedRate] || 0) + 1;
            });

            if (
                highestAccuracyInfo === null ||
                parseInt(item.accuracy_rank, 10) < parseInt(highestAccuracyInfo.accuracy_rank, 10)
            ) {
                highestAccuracyInfo = {
                    accuracy_rank: item.accuracy_rank,
                    info: item.info,
                    source: item.source
                };
            }
        });

        let mostFrequentRate = null;
        let maxCount = 0;
        for (const rate in rateCounts) {
            if (rateCounts[rate] > maxCount) {
                mostFrequentRate = rate;
                maxCount = rateCounts[rate];
            }
        }

        if (data?.metadata.destination === "840" && original) {
            mostFrequentRate = "multiple state rates.";
            highestAccuracyInfo.info = "Click here to view full details";
        }

        let finalData = {
            mostFrequentRate,
            highestAccuracyInfo: highestAccuracyInfo?.info || "N/A",
        }

        if (!original) {
            finalData = {
                ...finalData,
                highestAccuracySource: highestAccuracyInfo?.source
            }
        }

        return finalData;
    };

    const { mostFrequentRate, highestAccuracyInfo, highestAccuracySource } = getMostOccurringRateAndInfo(data?.vat?.data);

    return (
        <div>
            {data?.vat &&
                <div key={1} className="candidate-education-content mt-4 d-flex">
                    <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
                        <i className="uil uil-percentage"></i>
                    </div>
                    <div className="ms-4">
                        <h6 className="fs-16 mb-1">
                            VAT: <b>{mostFrequentRate || "N/A"}</b>
                        </h6>
                        <p className="mb-2 text-muted">
                            {!original ? (
                                highestAccuracyInfo
                            ) : (
                                <Link to={`/regulation-details?id=${encodeURIComponent(data.metadata.product)}&type=VAT Rate&product=${encodeURIComponent(data.metadata.product)}&destination=${encodeURIComponent(data.metadata.destination)}&origin=${encodeURIComponent(data.metadata.origin)}`}>
                                    {highestAccuracyInfo}
                                </Link>
                            )}
                        </p>
                        {highestAccuracySource && (<p className="mb-2 text-muted">Source: <a href={highestAccuracySource} target="_blank" rel="noopener noreferrer">{highestAccuracySource}</a></p>)}
                    </div>
                </div>
            }
        </div>
    );
};

export default VATComponent