import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import JobGridHeader from "./JobGridHeader";
import Section from "./Section";
import ImportRestrictions from "../../Home/JobList/ImportRestriction";
import { useLocation } from "react-router-dom";
import { isEU } from "Utils/Utils";
import fetchEnc from "Utils/FetchEnc";
import { useAuth } from "Utils/AuthContext";
import LogRequest from "Utils/Logging";

const JobGrid = () => {
  document.title = "Regulation Search | Smart HS Codes - HS Code and Regulations Match";

  const { isAuthenticated, userSubscription, isFetchingCredit, user } = useAuth();

  const [loading, setLoading] = useState(true);
  const [candidates, setData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const location = useLocation();
  let dataUrl = `https://mtech-api.com/web-app/`

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      product: searchParams.get('product'),
      destination: searchParams.get('destination'),
      searchType: searchParams.get('searchType') || "title", // Default to "product"
      page: searchParams.get('page'),
    };
  };

  const { product, destination, searchType, page } = getQueryParams();

  const fetchHSCodeData = async (userSubscription = null) => {
    const startTime = performance.now();

    let options = {};
    if (userSubscription !== null) {
      options = {
        userEmail: userSubscription.data.user_id,
        userSub: userSubscription.data.sub_id,
      }
    } else if (user?.email) {
      options = {
        userEmail: user.email,
        userSub: "free",
      }
    }
    if (!product || !destination || !searchType) {
      try {
        const qrUrl = `${dataUrl}import-export-restrictions-latest`;
        const antiDumpingUrl = `${dataUrl}duty-measures-latest`;
        const prUrl = `${dataUrl}product-requirements-latest`;
        const importLicensingUrl = `${dataUrl}import-licensing/country-regulations-latest`;
        const regulationPdfUrl = `${dataUrl}reg-docs?limit=50`;

        const [qrData, antiDumpingData, prData, importLicensingData, regulationPdfData] = await Promise.all([
          fetchEnc(qrUrl, options).then(res => res.json()),
          fetchEnc(antiDumpingUrl, options).then(res => res.json()),
          fetchEnc(prUrl, options).then(res => res.json()),
          fetchEnc(importLicensingUrl, options).then(res => res.json()),
          fetchEnc(regulationPdfUrl, options).then(res => res.json()),
        ]);

        setTotalCount(parseInt(qrData.count) + parseInt(antiDumpingData.count) + parseInt(prData.count) + parseInt(importLicensingData.count) + parseInt(regulationPdfData.count));
        setData([{
          qr: qrData,
          antidumping: antiDumpingData,
          pr: prData,
          importLicensing: importLicensingData,
          regulationPdf: regulationPdfData,
          metadata: { product: "top 50", destination: "top 50" }
        }]);
        localStorage.setItem("latestNotifTotalCount", parseInt(qrData.count) + parseInt(antiDumpingData.count) + parseInt(prData.count) + parseInt(importLicensingData.count) + parseInt(regulationPdfData.count));
      } catch (error) {
        console.error("Error fetching latest data: ", error);
      } finally {
        setLoading(false);
        return;
      }
    } else {

      setData([]); // Clear previous data
      setLoading(true); // Show the loading spinner at the start

      if (searchType === "title") {
        // Direct API calls using the product title
        try {
          let qrUrl = `${dataUrl}import-export-restrictions?title=${product}&reporter=${destination}`;
          let antiDumpingUrl = `${dataUrl}duty-measures?reporter=${destination}&title=${product}`;
          let prUrl = `${dataUrl}product-requirements?title=${product}&country=${destination}`;
          let importLicensingUrl = `${dataUrl}import-licensing/country-regulations?country=${destination}&title=${product}`;
          let regulationPdfUrl = `${dataUrl}reg-docs?title=${product}&country=${destination}`;
          
          const [qrData, antiDumpingData, prData, importLicensingData, regulationPdfData] = await Promise.all([
            fetchEnc(qrUrl, options).then(res => res.json()),
            fetchEnc(antiDumpingUrl, options).then(res => res.json()),
            fetchEnc(prUrl, options).then(res => res.json()),
            fetchEnc(importLicensingUrl, options).then(res => res.json()),
            fetchEnc(regulationPdfUrl, options).then(res => res.json()),
          ]);

          setTotalCount(localStorage.getItem("latestNotifTotalCount"));

          setData([{
            qr: qrData,
            antidumping: antiDumpingData,
            pr: prData,
            importLicensing: importLicensingData,
            regulationPdf: regulationPdfData,
            metadata: { product, destination, searchType }
          }]);
        } catch (error) {
          console.error("Error fetching data by title: ", error);
        } finally {
          setLoading(false);
        }
      } else {
        // Fetch HS codes and make API calls based on HS codes
        let hsCodeUrl = `${dataUrl}smart-hs?product=${product}&category=${destination}`;
        try {
          const response = await fetchEnc(hsCodeUrl, options);
          const data = await response.json();
          const hsCodes = data.data.six_digit_codes.filter(code => code.code6);

          if (hsCodes.length === 0) {
            setLoading(false); // Hide spinner if no HS codes found
            return;
          }

          // Fetch all HS code-related data
          const allRequests = hsCodes.map(async (code) => {
            const qrUrl = `${dataUrl}import-export-restrictions?product=${code.code6}&reporter=${destination}`;
            const antiDumpingUrl = `${dataUrl}duty-measures?reporter=${destination}&product=${code.code6}`;
            const prUrl = `${dataUrl}product-requirements?product=${code.code6}&country=${destination}`;
            const importLicensingUrl = `${dataUrl}import-licensing/country-regulations?country=${destination}&product=${code.code6}`;
            const regulationPdfUrl = `${dataUrl}reg-docs?title=${product}&country=${destination}`;

            const requests = [
              fetchEnc(qrUrl, options).then(res => res.json()).catch(() => null),
              fetchEnc(antiDumpingUrl, options).then(res => res.json()).catch(() => null),
              fetchEnc(prUrl, options).then(res => res.json()).catch(() => null),
              fetchEnc(importLicensingUrl, options).then(res => res.json()).catch(() => null),
              fetchEnc(regulationPdfUrl, options).then(res => res.json()).catch(() => null),
            ];

            setTotalCount(localStorage.getItem("latestNotifTotalCount"));

            if (isEU(destination)) {
              requests.push(fetchEnc(`${dataUrl}eu-taric?product=${product}`, options).then(res => res.json()).catch(() => null));
            }

            try {
              const [qrData, antiDumpingData, prData, importLicensingData, regulationPdfData, euTaricData] = await Promise.all(requests);

              setTotalCount(localStorage.getItem("latestNotifTotalCount"));

              setData(prev => [
                ...prev,
                {
                  qr: qrData,
                  antidumping: antiDumpingData,
                  pr: prData,
                  importLicensing: importLicensingData,
                  regulationPdf: regulationPdfData,
                  euTaric: euTaricData,
                  hs: code,
                  metadata: { product, destination, searchType },
                }
              ]);
            } catch (err) {
              console.error("Error fetching data for code: ", code.code6, err);
            }
          });

          // Wait for all requests to complete before hiding the spinner
          await Promise.all(allRequests);
        } catch (error) {
          console.error("Error fetching HS Code data: ", error);
        } finally {
          setLoading(false); // Hide spinner once all requests are done
        }
      }
    }

    const endTime = performance.now();
    const processingTime = endTime - startTime;
    const pathname = location.pathname.slice(1);
    const queryString = location.search.slice(1);

    if (isNaN(product)) {
      LogRequest(pathname, processingTime, queryString, options);
    }
  };

  useEffect(() => {
    if (isAuthenticated && userSubscription && !isFetchingCredit) {
      fetchHSCodeData(userSubscription);
    } else {
      fetchHSCodeData()
    }
  }, [location, userSubscription, user]);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <JobGridHeader
            initialProduct={product}
            initialDestination={destination}
            initialSearchType={searchType}
          />
          <Row>
            <Col lg={12}>
              {loading && (
                <div className="d-flex justify-content-center my-5">
                  <Spinner color="primary" />
                </div>
              )}
              <ImportRestrictions candidates={candidates} totalCount={totalCount} metadata={{ page }} />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobGrid;
