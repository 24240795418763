import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import LeftSideContent from "./LeftSideContent";
import RightSideContent from "./RightSideContent";
import Section from "./Section";
import { useLocation } from "react-router-dom";
import { isEU } from "Utils/Utils";
import fetchEnc from "Utils/FetchEnc";
import { useAuth } from "Utils/AuthContext";
import LogRequest from "Utils/Logging";

const CandidateDetails = () => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [hasBurnedCredit, setHasBurnedCredit] = useState(false);
  const location = useLocation();
  
  const { isAuthenticated, userCredit, burnUserCredit, userSubscription, isFetchingCredit, user } = useAuth();
  
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      product: searchParams.get('product'),
      destination: searchParams.get('destination'),
      origin: searchParams.get('origin'),
    };
  };
  
  const { product, destination, origin } = getQueryParams();
  
  document.title = `HS ${product} Import Duty and Regulations`;

  const fetchHSCodeData = async (userSubscription = null) => {
    if (!product || !destination || !origin) {
      setLoading(false);
      return;
    }
    const startTime = performance.now();
    setLoading(true);

    const baseUrl = `https://mtech-api.com/web-app/`;
    const code6 = product.slice(0, 6);

    // Helper function to update each part of the data as it arrives
    const updateData = (key, value) => {
      setData(prevData => ({
        ...prevData,
        [key]: value,
        metadata: { product, destination, origin },
      }));
    };

    let options = {};
    if (userSubscription !== null) {
      options = {
        userEmail: userSubscription.data.user_id,
        userSub: userSubscription.data.sub_id,
      }
    } else if (user?.email) {
      options = {
        userEmail: user.email,
        userSub: "free",
      }
    }

    // Define all the requests separately and update the data for each as they complete
    const requests = [
      fetchEnc(`${baseUrl}smart-hs?product=${product}&category=${destination}`, options)
        .then(res => res.json())
        .then(result => updateData('hs', result))
        .catch(err => console.error("Smart HS Error: ", err)),

      fetchEnc(`${baseUrl}import_duty?q=${code6}&dest_country=${destination}&origin_country=${origin}`, options)
        .then(res => res.json())
        .then(result => updateData('importDuty', result))
        .catch(err => console.error("Import Duty Error: ", err)),

      fetchEnc(`${baseUrl}import-export-restrictions?product=${code6}&reporter=${destination}`, options)
        .then(res => res.json())
        .then(result => updateData('qr', result))
        .catch(err => console.error("QR Error: ", err)),

      fetchEnc(`${baseUrl}duty-measures?reporter=${destination}&exporter=${origin}&product=${code6}`, options)
        .then(res => res.json())
        .then(result => updateData('antidumping', result))
        .catch(err => console.error("Anti Dumping Error: ", err)),

      fetchEnc(`${baseUrl}product-requirements?product=${code6}&country=${destination}`, options)
        .then(res => res.json())
        .then(result => updateData('pr', result))
        .catch(err => console.error("PR Error: ", err)),

      fetchEnc(`${baseUrl}import-licensing/country-regulations?country=${destination}&product=${code6}`, options)
        .then(res => res.json())
        .then(result => updateData('importLicensing', result))
        .catch(err => console.error("Import Licensing Error: ", err)),

      fetchEnc(`${baseUrl}vat-rate?country=${destination}`, options)
        .then(res => res.json())
        .then(result => updateData('vat', result))
        .catch(err => console.error("vat Error: ", err)),
    ];

    // Additional request if destination is in the EU
    if (isEU(destination)) {
      requests.push(
        fetchEnc(`${baseUrl}eu-taric?product=${code6}&country=${origin}`, options)
          .then(res => res.json())
          .then(result => updateData('euTaric', result))
          .catch(err => console.error("EU Taric Error: ", err))
      );
    }

    // Await all requests to remove the loading state when they finish
    await Promise.allSettled(requests);
    setLoading(false);
    const endTime = performance.now();
    const processingTime = endTime - startTime;
    const pathname = location.pathname.slice(1);
    const queryString = location.search.slice(1);
    if (userSubscription !== null) {
      LogRequest(pathname, processingTime, queryString, options);
    }
  };

  useEffect(() => {
    if (isAuthenticated && userSubscription && !isFetchingCredit) {
      fetchHSCodeData(userSubscription);
    } else {
      fetchHSCodeData()
    }
  }, [isAuthenticated, userSubscription]);

  useEffect(() => {
    if (isAuthenticated && userCredit > 0 && !hasBurnedCredit) {
      burnUserCredit(userCredit);
      setHasBurnedCredit(true);
    }
  }, [isAuthenticated, userCredit, burnUserCredit, hasBurnedCredit]);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            <LeftSideContent data={data} hasBurnedCredit={hasBurnedCredit} />
            <RightSideContent data={data} hasBurnedCredit={hasBurnedCredit} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CandidateDetails;
