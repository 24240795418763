import React, { useEffect, useState } from "react";
import { Col, Container, Row, Spinner } from "reactstrap";
import CandidateDetails from "./CandidateDetails";
import JobFilters from "./JobFilters";
import Section from "./Section";
import { useLocation } from "react-router-dom";
import { countryFlag, countryName } from "../../../Utils/Utils";
import fetchEnc from "Utils/FetchEnc";
import { useAuth } from "Utils/AuthContext";
import LogRequest from "Utils/Logging";

const CandidateList = () => {
  document.title = "HS Code Match Search Results";

  const { isAuthenticated, userSubscription, isFetchingCredit, user } = useAuth();

  const [loading, setLoading] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const location = useLocation();

  // Helper function to get query params
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      product: searchParams.get('product'),
      destination: searchParams.get('destination'),
      origin: searchParams.get('origin'),
      page: searchParams.get('page'),
    };
  };

  let { product, destination, origin, page } = getQueryParams();



  const fetchHSCodeData = async (userSubscription = null) => {

    if (!product || !destination || !origin) {
      /* window.location.href = ('/hs-code-search?product=watch%20spare%20parts&destination=276&origin=156'); */
      setLoading(false);
      return;
    }

    const startTime = performance.now();

    // Clear previous candidates before fetching new data
    setCandidates([]);
    setLoading(true); // Set loading to true before fetching

    let options = {};
    if (userSubscription !== null) {
      options = {
        userEmail: userSubscription.data.user_id,
        userSub: userSubscription.data.sub_id,
      }
    } else if (user?.email) {
      options = {
        userEmail: user.email,
        userSub: "free",
      }
    }

    // First API Call
    const firstApiUrl = `https://mtech-api.com/web-app/smart-hs?product=${product}&category=${destination}`;
    try {
      const response = await fetchEnc(firstApiUrl, options);
      const data = await response.json();
      const hsCodes = data.data.national_tariff_lines.filter(code => code.code);
      hsCodes.forEach(code => {
        code.origin_code = origin;
        code.country_flag_url = countryFlag(countryName(destination), 'w80');
        if (!isNaN(product)) {
          if (code.code === product) {
            code.accuracyRating = "100%"
          } else {
            code.accuracyRating = "0%"
          }
        }
      })
      setCandidates(prevCandidates => [...prevCandidates, ...hsCodes]);
    } catch (error) {
      console.error("Error fetching data: ", error);
      setLoading(false); // Set loading to false in case of error
    } finally {
      setLoading(false);
    }

    const endTime = performance.now();
    const processingTime = endTime - startTime;
    const pathname = location.pathname.slice(1);
    const queryString = location.search.slice(1);

    if (isNaN(product)) {
      if (!(product === "watch spare parts" && destination === "276" && origin === "156")) {
        LogRequest(pathname, processingTime, queryString, options);
      }
    }
  };

  useEffect(() => {
    if (isAuthenticated && userSubscription && !isFetchingCredit) {
      fetchHSCodeData(userSubscription);
    } else {
      fetchHSCodeData()
    }
  }, [location, userSubscription, user]);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          {/* Pass the query parameters to JobFilters */}
          <JobFilters
            initialProduct={product}
            initialDestination={destination}
            initialOrigin={origin}
          />
          <Row>
            <Col lg={12}>
              {loading && (
                <div className="d-flex justify-content-center my-5">
                  <Spinner color="primary" />
                </div>
              )}
              <CandidateDetails candidates={candidates} metadata={{ product, destination, origin, page }} />
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default CandidateList;
