import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row, Spinner } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { ConditionalWrapper, countryName, findCountriesInData, generateUniqueId, isEU } from "../../../Utils/Utils";
import RightSideContent from "./RightSideContent";
import BlurRandomTextWrapper from "Utils/BlurRandomTextWrapper";
import { useAuth } from "Utils/AuthContext";
import { toast } from "react-toastify";
import { Icon } from "@iconify/react";
import ExpandText from "Utils/ExpandText";
import { MeasureItem } from "pages/CandidateAndCompany/CandidateDetails/MeasureItem";
import fetchEnc from "Utils/FetchEnc";
import { Accordion } from "react-bootstrap";
import { buildHierarchy, renderAccordion, renderContentSections } from "./Sections";
import VATComponent from "./VAT";

function parseDate(dateStr) {
  const standardizedDate = dateStr.replace(/\//g, '-').replace('T00:00:00.000Z', '');

  const isDDMMYYYY = standardizedDate.match(/^\d{2}-\d{2}-\d{4}$/);
  if (isDDMMYYYY) {
    const [day, month, year] = standardizedDate.split('-');
    return `${day}-${month}-${year}`;
  }

  const isYYYYMMDD = standardizedDate.match(/^\d{4}-\d{2}-\d{2}$/);
  if (isYYYYMMDD) {
    const [year, month, day] = standardizedDate.split('-');
    return `${day}-${month}-${year}`;
  }

  return standardizedDate;
}

const findByCode = (code, data, metadata) => {
  let allItems;
  if (metadata?.product !== "top 50") {

    allItems = [
      ...data.antidumping_investigations,
      ...data.antidumping_measures,
      ...data.countervailing_investigations,
      ...data.countervailing_measures,
    ];
  } else {
    allItems = [
      ...data.data
    ];
  }

  return allItems.find((item) => item.code === code);
};

const formatKey = (key) => {
  return key
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

const renderData = (data, ignoreList) => {
  if (typeof data === "object" && data !== null) {
    if (Array.isArray(data)) {
      return (
        <ul>
          {data.map((item, index) => (
            <li key={index}>{renderData(item, ignoreList)}</li>
          ))}
        </ul>
      );
    } else {
      const keys = Object.keys(data).filter((key) => {
        if (ignoreList.includes(key)) return false;
        const value = data[key];
        if (typeof value === "string" && value.startsWith("https") && value.includes("wto")) {
          return false;
        }
        return true;
      });
      return (
        <ul>
          {keys.map((key) => (
            <li key={key}>
              <strong>{formatKey(key)}: </strong>
              {renderData(data[key], ignoreList)}
            </li>
          ))}
        </ul>
      );
    }
  }
  return <span dangerouslySetInnerHTML={{ __html: data ? data.toString() : "N/A" }}></span>;
};

const detectLinks = (data) => {
  const links = [];
  const linkRegex = /\bhttps?:\/\/[\w./?=&%-:]+/g;

  const traverse = (obj) => {
    if (typeof obj === "object" && obj !== null) {
      if (Array.isArray(obj)) {
        obj.forEach((item) => traverse(item));
      } else {
        Object.values(obj).forEach((value) => traverse(value));
      }
    } else if (typeof obj === "string") {
      const matchedLinks = obj.match(linkRegex);
      if (matchedLinks) {
        matchedLinks.forEach((link) => {
          if (!link.includes("wto")) {
            links.push(link);
          }
        });
      }
    }
  };

  traverse(data);
  return links;
};

const getFilteredData = (data, ignoreList = ["footnotes", "pdf_url"]) => {
  if (typeof data === "object" && data !== null) {
    if (Array.isArray(data)) {
      return data.map((item) => getFilteredData(item, ignoreList));
    } else {
      return Object.keys(data)
        .filter((key) => !ignoreList.includes(key))
        .reduce((filtered, key) => {
          filtered[key] = getFilteredData(data[key], ignoreList);
          return filtered;
        }, {});
    }
  }
  return data;
};

const JobDetailsDescription = ({ data, isLoading, hasBurnedCredit }) => {
  const { isFetchingCredit, isAuthLoading } = useAuth();
  const [pdfPaths, setPdfPaths] = useState([]);
  const [ignoreList, setIgnoreList] = useState(["footnotes", "pdf_url", "row_num"]);
  const [loadingStates, setLoadingStates] = useState(pdfPaths.map(() => false));
  const [fetchingPdf, setFetchingPdf] = useState(false);
  const [sections, setSections] = useState([]);

  const [title, setTitle] = useState(null);
  const [dataRef, setDataRef] = useState(null);
  const [dataSummarize, setDataSummarize] = useState(null);
  const [citedCountries, setCitedCountries] = useState([]);
  const [dataLinks, setDataLinks] = useState([]);
  const [hsCodes, setHsCodes] = useState(null);
  const [uuid, setUuid] = useState(null);
  const [documentDate, setDocumentDate] = useState(null);
  const [measureItem, setMeasureItem] = useState(false);
  const [isImportDuty, setIsImportDuty] = useState(false);
  const [isfetchSections, setFetchSections] = useState(false);

  const [vat, setVAT] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      let tempDataRef = null;
      let tempUuid = null;

      if (["Antidumping Measures", "Antidumping Investigations", "Countervailing Measures", "Countervailing Investigations"].includes(data.metadata.type)) {
        tempDataRef = data?.data && findByCode(data.metadata.id, data.data, data.metadata);
        tempUuid = { uuid: generateUniqueId("DutyMeasure-", tempDataRef.code) };
        setDataRef({ ...tempUuid, ...tempDataRef });
        setTitle(`${data.metadata.type} - ${tempDataRef?.subject_product} - ${tempDataRef?.hs_section_label}`);
        setDocumentDate(tempDataRef.duty_measure_detail?.Measure?.InForceFrom || "N/A");
        setCitedCountries(findCountriesInData(tempDataRef));
        setDataSummarize(getFilteredData(tempDataRef));
        setDataLinks(detectLinks(tempDataRef));
        setHsCodes(tempDataRef?.duty_measure_detail?.OriginalInvestigation?.HsCodes?.map(item => item.hs_code));
        setMeasureItem(true);
        setUuid(tempUuid.uuid);
      } else if (data.metadata.type === "Import Licensing") {
        tempDataRef = data?.data?.data?.find((item) => item.title === data.metadata.id);
        tempUuid = { uuid: generateUniqueId("ImportLicensing-", tempDataRef.title) };
        setDataRef({ ...tempUuid, ...tempDataRef });
        setTitle(tempDataRef?.title);
        setDocumentDate(tempDataRef.legislation_detail?.LatestPublished || "N/A");
        setCitedCountries(findCountriesInData(tempDataRef));
        setDataSummarize(getFilteredData(tempDataRef));
        setDataLinks(detectLinks(tempDataRef));
        setHsCodes(tempDataRef?.mtechHSCode?.split(","));
        setUuid(tempUuid.uuid);
      } else if (data.metadata.type === "Import Export Restriction") {
        tempDataRef = data?.data?.data?.find((item) => item?.id === data.metadata.id);
        tempUuid = { uuid: generateUniqueId("ImportExportRestriction-", tempDataRef?.id) };
        setDataRef({ ...tempUuid, ...tempDataRef });
        setTitle(tempDataRef?.general_description);
        setDocumentDate(tempDataRef?.in_force_from || "N/A");
        setCitedCountries(findCountriesInData(tempDataRef));
        setDataSummarize(getFilteredData(tempDataRef));
        setDataLinks(detectLinks(tempDataRef));
        setHsCodes(tempDataRef?.affected_products?.map(item => item.code));
        setUuid(tempUuid.uuid);
      } else if (data.metadata.type === "Product Requirement") {
        tempDataRef = data?.data?.ProductRequirementsDetails?.find((item) => item?.documentSymbol === data.metadata.id);
        tempUuid = { uuid: generateUniqueId("ProductRequirement-", tempDataRef.documentSymbol) };
        setDataRef({
          ...tempUuid,
          ...tempDataRef,
          hsCodes: {
            HSCode: tempDataRef?.hsCodes?.map(item => item.HSCode).join(", "),
            HSCodeType: tempDataRef?.hsCodes[0]?.Source,
          },
        });
        setTitle(tempDataRef?.titlePlain);
        setDocumentDate(tempDataRef?.distributionDate || "N/A");
        setCitedCountries(findCountriesInData(tempDataRef));
        setDataSummarize(getFilteredData(tempDataRef));
        setDataLinks(detectLinks(tempDataRef));
        setHsCodes(tempDataRef?.hsCodes?.map(item => item.HSCode));
        setUuid(tempUuid.uuid);
      } else if (data.metadata.type === "Import Duty") {
        tempDataRef = data?.data?.FTAImportDuty?.find((item, index) =>
          index === 0 ||
          item.find(
            (subItem) =>
              (subItem.FTANameFull || subItem.FTAName) ===
              (data.metadata.id || data.metadata.FTAName)
          )
        )?.find(
          (subItem) =>
            (subItem.FTANameFull || subItem.FTAName) ===
            (data.metadata.id || data.metadata.FTAName)
        );
        setDataRef(tempDataRef);
        setTitle(tempDataRef?.FTANameFull || tempDataRef?.FTAName);
        setCitedCountries(findCountriesInData(tempDataRef));
        setDataSummarize(getFilteredData(tempDataRef));
        setDataLinks(detectLinks(tempDataRef));
        setIsImportDuty(true);
      } else if (["Legislation", "Notification"].includes(data.metadata.type)) {
        tempDataRef = data?.data?.data?.find((item) => item?.id?.toString() === data.metadata.id?.toString());
        tempUuid = { uuid: generateUniqueId("Regulation-", tempDataRef?.id) };
        setDataRef({ ...tempUuid, ...tempDataRef });
        setTitle(tempDataRef?.regulation_title?.replace("_notification", ""));
        setDocumentDate(tempDataRef?.document_date || "N/A");
        setDataSummarize(null);
        setDataLinks(detectLinks(tempDataRef));
        setUuid(tempUuid.uuid);
      } else if (data.metadata.type === "EU Commission") {
        tempDataRef = data?.data?.[0];
        setDataRef(tempDataRef);
        setTitle(tempDataRef?.title);
        setDocumentDate(tempDataRef?.last_update || "N/A");
        setCitedCountries([]);
        setDataSummarize(getFilteredData(tempDataRef));
        setDataLinks(detectLinks(tempDataRef));
      } else if (data.metadata.type === "VAT Rate") {
        tempDataRef = data?.data?.data;
        setDataLinks(detectLinks(tempDataRef));
        setDocumentDate(tempDataRef?.[0]?.updated_at?.split("T")[0] || "N/A");
        if (data.metadata.destination === "840") {
          tempDataRef = tempDataRef?.map((item) => {
            return {
              accuracy_rank: item.accuracy_rank,
              state_name: item.country_name,
              rate: item.rate,
              info: item.info,
              source: item.source,
            };
          })
        } else {
          tempDataRef = tempDataRef?.map((item) => {
            return {
              accuracy_rank: item.accuracy_rank,
              country_name: item.country_name,
              rate: item.rate,
              info: item.info,
              source: item.source,
            };
          })
        }
        setDataRef(tempDataRef);
        setVAT({ vat: { data: tempDataRef }, metadata: data.metadata });
        setTitle("VAT for " + countryName(data.metadata.destination));
      }
    }
  }, [data, isLoading]);

  useEffect(() => {
    const fetchPdfPaths = async () => {
      if (!title) return;

      try {
        setFetchingPdf(true);

        const response = await fetch(
          `https://mtech-api.com/web-app/regulation-pdf/fetch-pdfs?title=${title}&country=${data.metadata.destination}`, {
          headers: {
            "X-User-Email": "no.reply.moaah@gmail.com",
            "X-User-Sub": "free"
          }
        }
        );
        if (response.ok) {
          const result = await response.json();
          if (result.pdfPaths) {
            setPdfPaths((prevPaths) => [
              ...new Set([...prevPaths, ...result.pdfPaths]),
            ]);
          }
        }
      } catch (error) {
        console.error("Error fetching PDF paths:", error);
        toast.error("Failed to fetch PDFs.");
      } finally {
        setFetchingPdf(false);
      }
    };

    fetchPdfPaths();
  }, [title, data.metadata.destination]);

  const handleClick = async (pdfPath, index, pdfName) => {
    if (!hasBurnedCredit) {
      toast.error("Please Subscribe to Use This Function");
      setTimeout(() => {
        window.location.href = "/pricing";
      }, 5000);
      return;
    }

    try {
      setLoadingStates(prevState => {
        const newState = [...prevState];
        newState[index] = true;
        return newState;
      });

      const url = `https://mtech-api.com/web-app/regulation-pdf/stream-pdf?pdfPath=${encodeURIComponent(pdfPath)}`;
      const response = await fetch(url, {
        headers: {
          "X-User-Email": "no.reply.moaah@gmail.com",
          "X-User-Sub": "free"
        }
      });

      if (!response.ok) {
        throw new Error("Failed to fetch the PDF");
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");

      setTimeout(() => URL.revokeObjectURL(blobUrl), 10000);
    } catch (error) {
      console.error(`Error streaming PDF for ${pdfPath}:`, error);
      toast.error(`Failed to stream PDF for ${pdfName}.`);
    } finally {
      setLoadingStates(prevState => {
        const newState = [...prevState];
        newState[index] = false;
        return newState;
      });
    }
  };

  const fetchSections = async (id) => {
    try {
      setFetchSections(true);
      const response = await fetchEnc(`https://mtech-api.com/web-app/reg-section?uuid=${id}`);
      const data = await response.json();
      setSections(data?.data?.[0]?.sections || []);
    } catch (error) {

    } finally {
      setFetchSections(false);
    }
  };

  useEffect(() => {
    if ((["Legislation", "Notification"].includes(data.metadata.type)) && uuid) {
      fetchSections(uuid)
    }
  }, [uuid]);

  return (
    <React.Fragment>
      <Col lg={8}>

        <Card className="job-detail overflow-hidden">
          <CardBody className="p-4">
            <div>
              <Row>
                <Col md={10}>
                  {title &&
                    <h5 className="mb-1">
                      Title : {isLoading ? <Skeleton width={150} /> : title}
                    </h5>
                  }
                </Col>
                <Col lg={2}>
                  {isLoading ? (
                    <Skeleton circle={true} height={40} width={40} />
                  ) : (
                    <img
                      src={data?.countryFlag}
                      height="40"
                      alt=""
                      className="logo-dark"
                    />
                  )}
                </Col>
              </Row>
            </div>

            <div className="mt-4">
              <Row className="g-2">
                <Col>
                  <div className="border rounded-start p-3">
                    <p className="text-muted mb-0 fs-13">Category </p>
                    <p className="fw-medium fs-15 mb-0">
                      {isLoading ? <Skeleton width={100} /> : data.metadata.type}
                    </p>
                  </div>
                </Col>
                {documentDate && (
                  <Col>
                    <div className="border rounded-start p-3">
                      <p className="text-muted mb-0 fs-13">Document Date </p>
                      <p className="fw-medium fs-15 mb-0">
                        {isLoading ? <Skeleton width={100} /> : parseDate(documentDate)}
                      </p>
                    </div>
                  </Col>
                )}
                <Col>
                  <div className="border p-3">
                    <p className="text-muted fs-13 mb-0"> Country </p>
                    <p className="fw-medium mb-0">
                      {isLoading ? (
                        <Skeleton width={100} />
                      ) : (
                        `${data?.countryName}`
                      )}
                    </p>
                  </div>
                </Col>
              </Row>
              {measureItem && (
                <Row className="g-2 mt-1">
                  <Col>
                    <div className="border rounded-start p-3">
                      <MeasureItem title={"Antidumping Measures"} item={dataRef} data={{}} citedCountry={{}} noLink={false} />
                    </div>
                  </Col>
                </Row>
              )}
              {vat && (
                <Row className="g-2 mt-1">
                  <Col>
                    <div className="border rounded-start p-3">
                      <VATComponent data={vat} original={false} />
                    </div>
                  </Col>
                </Row>
              )}

              {isLoading ? (
                <Skeleton count={3} width={80} height={30} />
              ) : citedCountries?.length > 0 ? (
                <><div className="mt-3">
                  <p className="fw-medium fs-15 mb-0">Cited Countries </p>
                </div>
                  <div className="mt-1 d-flex flex-wrap align-items-start gap-1">
                    {citedCountries.map((item, idx) => (
                      <span key={idx} className="badge bg-primary">
                        {item}
                      </span>
                    ))}
                  </div> </>
              ) : (null)}

              {isLoading ? (
                <Skeleton count={3} width={80} height={30} />
              ) : hsCodes?.length > 0 ? (
                <div className="mt-3">
                  <p className="fw-medium fs-15 mb-0">HS Codes</p>
                  <div className="mt-1 d-flex flex-wrap align-items-start gap-1">
                    {hsCodes.map((country, index) => (
                      <span key={index} className="badge bg-danger">
                        {country}
                      </span>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="mt-4">
              <h5 className="mb-3">Regulation Details </h5>
              {isFetchingCredit || isAuthLoading ? (
                <>
                  <br />
                  <Skeleton width={1000} height={20} />
                  <Skeleton width={600} height={20} />
                  <Skeleton width={400} height={20} />
                </>
              ) : (
                <ConditionalWrapper
                  condition={hasBurnedCredit}
                  wrapper={(children) => <BlurRandomTextWrapper>{children}</BlurRandomTextWrapper>}
                >
                  <div className="job-detail-desc">
                    {isLoading || isfetchSections ? <Skeleton count={5} /> : sections.length > 0 ?
                      <Accordion defaultActiveKey="0">
                        <div>{renderContentSections(buildHierarchy(sections))}</div>
                      </Accordion>
                      : renderData(dataRef, ignoreList)}
                  </div>
                </ConditionalWrapper>
              )}
            </div>
          </CardBody>
        </Card>
      </Col>
      <Col lg={4} className="mt-4 mt-lg-0">
        {fetchingPdf && (
          <Card className="job-overview mb-1">
            <CardBody className="p-4">
              <Skeleton width={250} />
              <Skeleton width={200} />
            </CardBody>
          </Card>
        )}

        {isLoading || pdfPaths.length === 0 || isFetchingCredit || isAuthLoading ?
          null :
          (
            <Card className="mb-3">
              <CardBody className="p-3">
                <div className="d-flex flex-column">
                  {pdfPaths.map((pdfPath, index) => {
                    const pdfName = pdfPath.split('/').pop();

                    return (
                      <div
                        key={index}
                        className="d-flex align-items-center mb-2"
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClick(pdfPath, index, pdfName)}
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Icon
                            icon="mdi:file-pdf-box"
                            width={40}
                            height={40}
                            style={{ color: '#ff0000', flexShrink: 0 }}
                          />
                          <h6
                            className="mb-0 ms-3 fs-17"
                            style={{
                              wordBreak: 'break-word',
                              margin: 0,
                            }}
                          >
                            <ExpandText text={pdfName} maxLength={20} />
                          </h6>
                          {loadingStates[index] && (
                            <Spinner
                              style={{ marginLeft: '10px' }}
                              size="sm"
                              color="primary"
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </CardBody>
            </Card>
          )}

        {isLoading || dataLinks.length === 0 || isFetchingCredit || isAuthLoading ? null : (
          <Card className="mb-3">
            <CardBody className="p-3">
              <h6 className="fs-17">Source URLs</h6>

              {dataLinks.map((link, index) => (
                <React.Fragment key={index}>
                  <a href={link} target="_blank" rel="noopener noreferrer" className="d-block">
                    <span>{link}</span>
                    <Icon icon="mdi:open-in-new" className="ms-2" />
                  </a>
                  {index < dataLinks.length - 1 && <br className="my-2" />}
                </React.Fragment>
              ))}
            </CardBody>
          </Card>
        )}

        {isLoading || isFetchingCredit || isAuthLoading ? (
          <Card className="job-overview">
            <CardBody className="p-4">
              <Skeleton width={250} />
              <Skeleton width={200} />
            </CardBody>
          </Card>
        ) : (
          <RightSideContent data={dataSummarize} isImportDuty={isImportDuty} hasBurnedCredit={hasBurnedCredit} sections={sections} />
        )}

        {isFetchingCredit || isAuthLoading ? (
          <>
            <br />
            <Skeleton width={250} />
            <Skeleton width={200} />
            <Skeleton width={150} />
          </>
        ) : (
          <ConditionalWrapper
            condition={hasBurnedCredit}
            wrapper={(children) => <BlurRandomTextWrapper>{children}</BlurRandomTextWrapper>}
          >
            {isLoading || isfetchSections ? <Skeleton count={5} /> : sections.length > 0 ?
              <Card className="job-overview">
                <CardBody className="p-4">
                  <Accordion defaultActiveKey="0">
                    {renderAccordion(buildHierarchy(sections))}
                  </Accordion>
                </CardBody>
              </Card>
              : null}
          </ConditionalWrapper>
        )}
      </Col>
    </React.Fragment>
  );
};


export default JobDetailsDescription;
