import Link from "Utils/LinkWrapper";
import { countryName } from "Utils/Utils";

export const MeasureItem = ({ title, item, data, citedCountry, noLink = true }) => (
    <div id={title} className={`candidate-education-content ${noLink ? "mt-4" : ""} d-flex`}>
        <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
            <i className="uil uil-percentage"></i>
        </div>
        <div className="ms-4">
            {noLink && (
                <>
                    <Link to={`/regulation-details?id=${encodeURIComponent(item.code)}&type=${title}&product=${encodeURIComponent(data.metadata.product)}&destination=${data.metadata.destination}&origin=${data.metadata.origin}`} className="mb-0"> <h6 className="fs-16 mb-1">{title + " - " + item.subject_product + " - " + item.hs_section_label}</h6></Link><div className="mt-2 mb-2">
                        {citedCountry?.length > 0 ? (
                            citedCountry.map((country, index) => (
                                <span key={index} className={`badge ${country === countryName(data.metadata.origin) ? "bg-success" : "bg-primary"} me-1`}>
                                    {country !== countryName(data.metadata.destination) ? country : null}
                                </span>
                            ))
                        ) : (
                            null
                        )}
                    </div>
                </>
            )}
            <p className="mb-0 text-muted">Exporting Country/Customs Territory: {item.exporting_member}</p>
            <p className="mb-0 text-muted">Subject Product: {item.subject_product}</p>
            <p className="mb-0 text-muted">HS Section: {item.hs_section_label}</p>
            <p className="mb-0 text-muted">Range Of Individual Margins: <b>{item.duty_measure_detail.FinalDetermination?.RangeOfIndividualMargins || item.duty_measure_detail.Measure?.RangeOfIndividualMargins}</b></p>
            <p className="mb-0 text-muted">All Other Rates: <b>{item.duty_measure_detail.FinalDetermination?.AllOtherRates || item.duty_measure_detail.Measure?.AllOtherRates}</b></p>
            <p className="mb-0 text-muted">HS Codes: <b>{item.mtechHSCode ? item.mtechHSCode.replace("{", "").replace("}", "") : ""}</b></p>
        </div>
    </div>
);