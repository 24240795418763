import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ExpandText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showFull: false
        };
    }

    handleMouseEnter = () => {
        this.setState({ showFull: true });
    };

    handleMouseLeave = () => {
        this.setState({ showFull: false });
    };

    render() {
        const { text: textProp, maxLength, className } = this.props;
        const text = typeof textProp === 'string' ? textProp : '';
        let visibleText;

        if (this.state.showFull || text.length <= maxLength) {
            visibleText = text;
        } else {
            const firstHalf = text.substring(0, maxLength / 2);
            const secondHalf = text.substring(text.length - maxLength / 2);
            visibleText = `${firstHalf}...${secondHalf}`;
        }

        return (
            <span
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                className={className}
                style={{
                    position: 'relative',
                    cursor: 'pointer',
                    display: 'inline-block',
                    whiteSpace: 'normal',
                    lineBreak: 'break-word',
                    wordBreak: 'break-word'
                }}
            >
                {visibleText}
                <span
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: -1,
                        content: '""',
                    }}
                />
            </span>
        );
    }
}

ExpandText.propTypes = {
    text: PropTypes.string.isRequired,
    maxLength: PropTypes.number.isRequired,
    className: PropTypes.string
};

ExpandText.defaultProps = {
    className: ''
};

export default ExpandText;
