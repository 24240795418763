import ExpandText from "Utils/ExpandText";
import Link from "Utils/LinkWrapper";
import { countryName } from "Utils/Utils";

export const Regulation = ({ title, type, date, date_type, data, id, hs, citedCountry, noLink = true }) => (
    <div id={title} className={`candidate-education-content ${noLink ? "mt-4" : ""} d-flex`}>
        <div className="circle flex-shrink-0 bg-primary-subtle text-primary">
            <i className="uil uil-exclamation-triangle"></i>
        </div>
        <div className="ms-4">
            {noLink && (
                <>
                    <Link to={`/regulation-details?id=${encodeURIComponent(id)}&type=${type}&product=${encodeURIComponent(data.metadata.product)}&destination=${data.metadata.destination}&origin=${data.metadata.origin}`} className="mb-0"><h6 className="fs-16 mb-1" style={{ wordBreak: 'break-word' }}>{type}</h6></Link>
                    <p className="mb-0 text-muted"><ExpandText text={title} maxLength={300} /></p>
                    <div className="mb-2 mt-2">
                        {citedCountry?.length > 0 ? (
                            citedCountry.map((country, index) => (
                                <span key={index} className={`badge ${country === countryName(data.metadata.origin) ? "bg-success" : "bg-primary"} me-1`}>
                                    {country !== countryName(data.metadata.destination) ? country : null}
                                </span>
                            ))
                        ) : (
                            null
                        )}
                    </div>
                </>
            )}
            <p className="mb-0 text-muted">{date_type}: {date}</p>
            {hs && <p className="mb-0 text-muted">HS Codes: <ExpandText text={hs} maxLength={100} /></p>}
        </div>
    </div>
)