import React, { useEffect, useState } from "react";
import { Container, Row } from "reactstrap";
import JobDetailsDescription from "./JobDetailsDescription";
import Section from "./Section";
import { useLocation } from "react-router-dom";
import { countryFlag, countryName } from "../../../Utils/Utils";
import fetchEnc from "Utils/FetchEnc";
import { useAuth } from "Utils/AuthContext";
import LogRequest from "Utils/Logging";

const JobDetails = () => {
  document.title = "Regulation Details | Smart HS Codes - HS Code and Regulations Match ";
  const { isAuthenticated, userCredit, burnUserCredit, userSubscription, isFetchingCredit, user } = useAuth();

  const [hasBurnedCredit, setHasBurnedCredit] = useState(false);

  const location = useLocation();

  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return {
      id: searchParams.get('id'),
      type: searchParams.get('type'),
      product: searchParams.get('product'),
      destination: searchParams.get('destination'),
      origin: searchParams.get('origin') || null,
      searchType: searchParams.get('searchType') || "product",
    };
  };

  const metadata = getQueryParams();
  const [data, setData] = useState({
    data: null,
    metadata: metadata,
    countryFlag: countryFlag(countryName(metadata.destination), "w80"),
    countryName: countryName(metadata.destination),
  });
  let dataUrl = `https://mtech-api.com/web-app/`
  if (metadata.type === 'Antidumping Measures' || metadata.type === 'Antidumping Investigations' || metadata.type === 'Countervailing Measures' || metadata.type === 'Countervailing Investigations') {
    if (metadata.product !== 'top 50') {
      dataUrl = `${dataUrl}duty-measures?reporter=${metadata.destination}&${metadata.searchType}=${metadata.product}`
    } else {
      dataUrl = `${dataUrl}duty-measures-latest?id=${encodeURIComponent(metadata.id)}`
    }
    if (metadata.origin) {
      dataUrl = `${dataUrl}&exporter=${metadata.origin}`
    }
  } else if (metadata.type === 'Import Licensing') {
    if (metadata.product !== 'top 50') {
      dataUrl = `${dataUrl}import-licensing/country-regulations?country=${metadata.destination}&${metadata.searchType}=${metadata.product}`
    } else {
      dataUrl = `${dataUrl}import-licensing/country-regulations-latest?id=${encodeURIComponent(metadata.id)}`
    }
  } else if (metadata.type === 'Import Export Restriction') {
    if (metadata.product !== 'top 50') {
      dataUrl = `${dataUrl}import-export-restrictions?${metadata.searchType}=${metadata.product}&reporter=${metadata.destination}`
    } else {
      dataUrl = `${dataUrl}import-export-restrictions-latest?id=${encodeURIComponent(metadata.id)}`
    }
  } else if (metadata.type === 'Product Requirement') {
    if (metadata.product !== 'top 50') {
      dataUrl = `${dataUrl}product-requirements?${metadata.searchType}=${metadata.product}&country=${metadata.destination}`
    } else {
      dataUrl = `${dataUrl}product-requirements-latest?id=${encodeURIComponent(metadata.id)}`
    }
  } else if (metadata.type === 'Import Duty') {
    if (metadata.product !== 'top 50') {
      dataUrl = `${dataUrl}import_duty?q=${metadata.product}&dest_country=${metadata.destination}&origin_country=${metadata.origin}`
    }
  } else if (metadata.type === 'Legislation' || metadata.type === 'Notification') {
    if (metadata.product !== 'top 50') {
      dataUrl = `${dataUrl}reg-docs?title=${metadata.product}&country=${metadata.destination}`
    } else {
      dataUrl = `${dataUrl}reg-docs?limit=50&id=${metadata.id}`
    }
  } else if (metadata.type === 'EU Commission') {
    if (metadata.product !== 'top 50') {
      if (metadata.origin) {
        dataUrl = `${dataUrl}eu-taric?product=${metadata.product}&country=${metadata.origin}`
      } else {
        dataUrl = `${dataUrl}eu-taric?product=${metadata.product}`
      }
    }
  } else if (metadata.type === 'VAT Rate') {
    if (metadata.product !== 'top 50') {
      dataUrl = `${dataUrl}vat-rate?country=${metadata.destination}`
    }
  }

  const fetchData = async (userSubscription = null) => {
    const startTime = performance.now();
    let options = {};

    try {
      if (userSubscription !== null) {
        options = {
          userEmail: userSubscription.data.user_id,
          userSub: userSubscription.data.sub_id,
        }
      } else if (user?.email) {
        options = {
          userEmail: user.email,
          userSub: "free",
        }
      }

      const response = await fetchEnc(dataUrl, options);
      const data = await response.json();
      setData(
        {
          data: data,
          metadata: metadata,
          countryFlag: countryFlag(countryName(metadata.destination), "w80"),
          countryName: countryName(metadata.destination),
        }
      );
    } catch (error) {
      console.log(error);
    }

    const endTime = performance.now();
    const processingTime = endTime - startTime;
    const pathname = location.pathname.slice(1);
    const queryString = location.search.slice(1);

    if (userSubscription !== null) {
      LogRequest(pathname, processingTime, queryString, options);
    }
  }

  useEffect(() => {
    if (isAuthenticated && userSubscription && !isFetchingCredit) {
      fetchData(userSubscription);
    } else {
      fetchData();
    }
  }, [isAuthenticated, userSubscription]);

  useEffect(() => {
    if (isAuthenticated && userCredit > 0 && !hasBurnedCredit) {
      burnUserCredit(userCredit);
      setHasBurnedCredit(true);
    }
  }, [isAuthenticated, userCredit, burnUserCredit, hasBurnedCredit]);

  return (
    <React.Fragment>
      <Section />
      <section className="section">
        <Container>
          <Row>
            <JobDetailsDescription data={data} isLoading={!data.data} hasBurnedCredit={hasBurnedCredit} />
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default JobDetails;
